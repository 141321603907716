import React from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import Testseries from "./Testseries";
import PageTitle from './PageTitle';

const ProductDetail = () => {
  const { productId } = useParams();

  const productData = {
    // testseries: <Testseries />,
    testseries: {
      component: <Testseries />,
      type: "Testseries",
    },
  };

  const product = productData[productId];

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <>
    <PageTitle title="Product | GatikAI Tech" />
      <Box
        className="product-detail-main-box"
        sx={{
          minHeight: "auto",
          display: "flex",
          paddingTop: "100px",
          m: "50px",
          flexDirection: "column",
        }}
      >
        {/* {product?.type?.name === "Testseries" ? <Testseries /> : null} */}
        {product.component}
      </Box>
    </>
  );
};

export default ProductDetail;
