import React, {useEffect} from "react";
import { Box, Container, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useLocation } from 'react-router-dom';
import PageTitle from '../Components/PageTitle';
import {FontSize , FontWeight, Fontfamily} from "../common";

const TermsandConditions = () => {
     const ScrollToTop = () => {
         const { pathname } = useLocation();
    
         useEffect(() => {
           window.scrollTo(0, 150);
         }, [pathname]);
    
         return null;
       };
  return (
    <>
    <ScrollToTop />
      <Container>
      <PageTitle title="Terms and Conditions for Using GatikAI Tech Service's" />
        <Box
          sx={{
            minHeight: "auto",
            mt: 11, 
            mb: 7,
            backgroundColor: "#f4f4f4",
            p: 5,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mt: 5,  
              mb: 5,
              fontWeight: FontWeight.bold,
              letterSpacing: "1.5px",
              fontFamily: Fontfamily.poppins,
            }}
          >
            Terms and Conditions
          </Typography>

          <Typography variant="body1" sx={{ mt: 2, lineHeight: "1.7", letterSpacing: "1px", fontFamily: Fontfamily.poppins,}}>
            <FiberManualRecordIcon sx={{ color: "#000", fontSize: FontSize.ten }} />{" "}
            No material from this site may be copied, modified, reproduced,
            republished, uploaded, transmitted, posted or distributed in any
            form without prior written permission from GatikAi Technologies. All
            rights not expressly granted herein are reserved.
          </Typography>

          <Typography variant="body1"  sx={{ mt: 2, lineHeight: "1.7", letterSpacing: "1px", fontFamily: Fontfamily.poppins,}}>
            <FiberManualRecordIcon sx={{ color: "#000", fontSize: FontSize.ten }} />{" "}
            The GatikAi Technologies name and logo are trademarks that belong to
            and are owned by GatikAi Technologies. These trademarks may not be
            used in any manner without prior written consent from GatikAi
            Technologies.
          </Typography>

          <Typography variant="body1"  sx={{ mt: 2, lineHeight: "1.7", letterSpacing: "1px", fontFamily: Fontfamily.poppins,}}>
            <FiberManualRecordIcon sx={{ color: "#000", fontSize: FontSize.ten }} />{" "}
            Unauthorized use of the materials appearing on this Site may violate
            copyright, trademark and other applicable laws. Such use could
            result in criminal or civil penalties. GatikAi Technologies may
            monitor access to the Site.
          </Typography>

          <Typography variant="body1"  sx={{ mt: 2, lineHeight: "1.7", letterSpacing: "1px", fontFamily: Fontfamily.poppins,}}>
            <FiberManualRecordIcon sx={{ color: "#000", fontSize: FontSize.ten }} />{" "}
            GatikAi Technologies may terminate a User’s access to the Site at
            any time and for any reason. The provisions regarding disclaimer of
            warranty, accuracy of information and indemnification shall survive
            such termination.
          </Typography>

          <Typography variant="body1"  sx={{ mt: 2, lineHeight: "1.7", letterSpacing: "1px", fontFamily: Fontfamily.poppins,}}>
            <FiberManualRecordIcon sx={{ color: "#000", fontSize: FontSize.ten }} />{" "}
            GatikAi Technologies may change or discontinue any aspect of the
            Site at any time, including its content or features.
          </Typography>

          <Typography variant="body1"  sx={{ mt: 2, lineHeight: "1.7", letterSpacing: "1px", fontFamily: Fontfamily.poppins,}}>
            <FiberManualRecordIcon sx={{ color: "#000", fontSize: FontSize.ten }} />{" "}
            GatikAi Technologies shall not be liable for damages of any kind,
            including and without limitation to direct, incidental or
            consequential damages (including, but not limited to, damages for
            lost profits, business interruption, and loss of programs or
            information) arising out of the use of or inability to use the Site,
            any information provided on the Site, or for any other reason
            whatsoever.
          </Typography>

          <Typography variant="body1"  sx={{ mt: 2, lineHeight: "1.7", letterSpacing: "1px", fontFamily: Fontfamily.poppins,}}>
            <FiberManualRecordIcon sx={{ color: "#000", fontSize: FontSize.ten }} />{" "}
            The Site provides links to websites and access to content, products
            and services from third parties, including Users, affiliates and
            sponsors of the Site.  GatikAi Technologies is not responsible for the
            availability of, or content provided on, third party websites.
            Before accessing or using such websites,  GatikAi Technologies encourages
            Users to read their policies regarding privacy and other topics.
            GatikAi Technologies is not responsible for third party content accessible
            through the Site, including opinions, advice, statements and
            advertisements. The User shall bear all risks associated with the
            use of such content.  GatikAi Technologies is not responsible for any loss or
            damages the User may incur from dealing with any third party.
          </Typography>

          <Typography variant="body1"  sx={{ mt: 2, lineHeight: "1.7", letterSpacing: "1px", fontFamily: Fontfamily.poppins,}}>
            <FiberManualRecordIcon sx={{ color: "#000", fontSize: FontSize.ten }} />{" "}
            GatikAi Technologies makes no warranty that: (a) the Site will meet the
            User’s requirements; (b) the Site will be available on an
            uninterrupted, timely, secure or error-free basis; (c) the results
            that may be obtained from the use of the Site or any services
            offered through the Site will be accurate or reliable.
          </Typography>

          <Typography variant="body1"  sx={{ mt: 2, lineHeight: "1.7", letterSpacing: "1px", fontFamily: Fontfamily.poppins,}}>
            <FiberManualRecordIcon sx={{ color: "#000", fontSize: FontSize.ten }} />{" "}
            GatikAi Technologies may at any time revise this terms of service. Users are
            bound by the most current terms of service each time they visit this
            Site.  GatikAi Technologies encourages Users to periodically review these
            terms of service to which they are bound.
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default TermsandConditions;  
